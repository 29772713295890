import React, { Component } from "react";
import {
	BrowserRouter,
	Route,
	Redirect,
	Switch,
	useLocation,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { useSelector } from "react-redux";
import "./App.css";

import BackgroundLoader from "./BackgroundLoader";
import ErrorFallback from "./ErrorBoundry/ErrorFallback";
import PrivateRoute from "./PrivateRoutes";

import Login from "./Login/Login";
import ValidateOtp from "./Login/ValidateOtp";
import IdleSessionContainer from "./Common/IdleSessionContainer";


import Layout from "./Common/Layout";
//const Layout = React.lazy(() => import("./Common/Layout"));
//import Home from "./Home/Home";
// import AvailableRoutes from "./AvailableRoutes";
const Events = React.lazy(() => import("./Home/Events"));
const Home = React.lazy(() => import("./Home/Home"));
const Bingo = React.lazy(() => import("./Bingo/Bingo"));
const Badge = React.lazy(() => import("./Badge/Badge"));
const LeaderBoard = React.lazy(() => import("./LeaderBoard/LeaderBoard"));

const VirtualSessions = React.lazy(() =>
	import("./VirtualSessions/VirtualSessions")
);
const FeedBack = React.lazy(() => import("./Bingo/FeedBack"));
const FAQs = React.lazy(() => import("./FAQ/FAQ"));
export default function App() {
	//   static displayName = App.name;
	// const location  = useLocation();
	const eventMenu = useSelector((state) => state.event.navigationMenuItems);

	// console.log("eventMenu",eventMenu);

	const eventMenuArray = eventMenu?.map((item) =>
		item?.nav_item?.toLowerCase().replace(" ", "")
	);
	// console.log("Event-menu",eventMenu);
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<React.Suspense fallback={<BackgroundLoader />}>
				<BrowserRouter>
					<Switch>
						<PersistGate loading={null} persistor={persistor}>
							<Route exact path="/Login" component={Login} />
							<Route exact path="/ValidateOtp" component={ValidateOtp} />

							<PrivateRoute>
								<IdleSessionContainer>
									<Switch>
										<Route exact path="/Events" component={Events} />
										<Layout>
											{/* <AvailableRoutes /> */}
											<Route exact path="/(|Home)" component={Home} />
											<Route exact path="/(Skills)/" component={Home} />
											<Route
												exact 
												path="/(Bingo|Dronacharya|Grow|Lead)/"
												component={
													eventMenuArray?.includes("bingo") ||
													eventMenuArray?.includes("dronacharya") ||
													eventMenuArray?.includes("grow") ||
													eventMenuArray?.includes("lead") 
													? Bingo : Home
												}
											/>
											<Route
												exact
												path="/Badge"
												component={
													eventMenuArray?.includes("badge") ? Badge : Home
												}
											/>

											<Route exact path="/feedback" component={FeedBack} />

											<Route
												exact
												path="/LeaderBoard"
												component={
													eventMenuArray?.includes("leaderboard")
														? LeaderBoard
														: Home
												}
											/>
											<Route
												exact
												path="/(VirtualSessions|LeadershipConversations)/"
												component={
													eventMenuArray?.includes("virtualsessions")|| eventMenuArray?.includes("leadershipconversations")
														? VirtualSessions
														: Home
												}
											/>
											{eventMenuArray?.includes("faqs") ? (
												<Route exact path="/Faqs" component={FAQs} />
											) : (
												<Redirect to={"/"} />
											)}
										</Layout>
									</Switch>
								</IdleSessionContainer>
							</PrivateRoute>
						</PersistGate>
					</Switch>
				</BrowserRouter>
			</React.Suspense>
		</ErrorBoundary>
	);
}
