import {
	REFRESH_BINGO,
} from "./RefreshBingoActionTypes";
import { initialState } from "../initialState";

const RefreshBingoReducer = (state = initialState, action) => {
	switch (action.type) {
		case REFRESH_BINGO:
			return {
				...state,
				refreshBingo: action.payload,
			};
		default:
			return state;
	}
};

export default RefreshBingoReducer;
