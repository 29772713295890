import {
	FETCH_CATEGORY_REQUEST,
	FETCH_CATEGORY_SUCCESS,
	FETCH_CATEGORY_FAILURE,
	UPDATE_SKILL_ID,
} from "./CategoryActionTypes";
import { initialState } from "../initialState";

const CategoryActionReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CATEGORY_SUCCESS:
			return {
				...state,
				category: action.payload,
			};
		case FETCH_CATEGORY_FAILURE:
			return {
				...state,
				category: action.payload,
			};
		default:
			return state;
	}
};

export default CategoryActionReducer;
