import {
	FETCH_CONTENT_SUCCESS,
	FETCH_CONTENT_FAILURE,
	FETCH_RESOURCE_URL,
} from "./ContentActionTypes";
import { initialState } from "../initialState";

const ContentActionReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CONTENT_SUCCESS:
			return {
				...state,
				content: action.payload,
			};
        case FETCH_CONTENT_FAILURE:
        return {
            ...state,
            content: action.payload,
        };
		case FETCH_RESOURCE_URL:
			return {
				...state,
				resourceURL: action.payload,
			};
		default:
			return state;
	}
};

export default ContentActionReducer;
