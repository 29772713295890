import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
	fetchCategorySuccess,
	fetchCategoryFailure,
} from "../redux/CategoryDetails/CategoryActions";
import { SetActiveUnit } from "../redux/ActiveUnitDetails/ActiveUnitActions";
import { setNavItems } from "../redux/EventDetails/EventActions";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "../css/SidebarStyle.css";
import { useHistory, useLocation } from "react-router-dom";
import Logoimage from "../img/Retail-Logo.svg";
// import {
// 	fetchUserSuccess,
// 	setUserLogout,
// } from "../redux/UserDetails/UserActions";
import "./SideBar.css";
import checkSession from "./checkSession";

function SideBar() {
	const history = useHistory();
	const location = useLocation();
	let statevalue = useSelector((state) => state.activeUnit.activeUnit);
	const dispatch = useDispatch();
	const [categories, Setcategories] = useState([]);
	const [apiloading, Setapiloading] = useState(true);
	const [sidebarview, Setsidebarview] = useState(false);
	const [showEventHeading, setShowEventHeading] = useState(false);
	const [updateUI, setUpdateUI] = useState(false);

	// let arrowClass = "togglearrow animated fadeInLeft";
	let arrowClass = "togglearrow animated fadeInLeft hide-element";

	const [activecategory, Setactivecategory] = useState(
		useSelector((state) => state.activeUnit.activeUnit)["activeCategory"]
	);
	// console.log(activecategory, "activecategpry");
	const [activeSkill, SetactiveSkill] = useState(
		useSelector((state) => state.activeUnit.activeUnit)["activeSkill"]
	);
	const sidebarClose = {
		display: "none",
	};
	const sidebarOpen = {
		display: "block",
		overflow: "hidden",
		transform: "translateX(0%)",
		transition: "transform 0.4s ease ",
	};
	const event = useSelector((state) => state.event.event);
	const eventName = useSelector((state) => state.event.event.event_name);
	// console.log("event x is ", event);
	const quizLetters = useSelector((state) => state.event.quizLetters);
	const letterArray =
		quizLetters?.length == 0
			? ["B", "I", "N", "G", "O"]
			: quizLetters?.map((letter) => letter?.activity_letter);

	const eventMenu = useSelector((state) => state.event.navigationMenuItems);
	const currentCategory = sessionStorage.getItem("currentCategory")
	const currentActive = sessionStorage.getItem("currentActiveUnit")
	// console.log('event menu is ',eventMenu);
	useEffect(() => {
		async function getCategorySkillData() {
			let categorydata = await axios
				.get("/Home/api/Categories")
				.then((response) => {

					if (response?.data?.data?.data?.length == 0 || response?.data?.data?.nav_data?.length == 0) {
						setUpdateUI(true)
					}

					checkSession(response, dispatch, history);
					// response.data is the users
					// console.log("Side bar jsx",response.data);
					const categorydata = response?.data?.data?.data;
					Setcategories(categorydata);

					dispatch(fetchCategorySuccess(categorydata));
					// console.log("Navf items are", response.data.data);
					dispatch(setNavItems(response?.data?.data?.nav_data));

					let ActiveUnit = new Map();
					if (currentActive) {
						const filter = categorydata?.filter((f) => {
							return f?.category_name == JSON.parse(currentActive)?.activeCategory?.category_name
						})
						if (filter?.length > 0) {
							const filterSkill = filter?.[0].skill_List?.filter((f) => {
								return f?.skill_id == JSON.parse(currentActive)?.activeSkill?.skill_id
							})
							ActiveUnit["activeCategory"] = filter?.[0];
							ActiveUnit["activeSkill"] = filterSkill?.[0];
							dispatch(SetActiveUnit(ActiveUnit));
						} else {
							ActiveUnit["activeCategory"] = filter?.[0];
							ActiveUnit["activeSkill"] = filter?.[0]?.skill_List[0];
							dispatch(SetActiveUnit(ActiveUnit));

						}


					} else {
						ActiveUnit["activeCategory"] = categorydata?.[0];
						ActiveUnit["activeSkill"] = categorydata?.[0]?.skill_List[0];
						dispatch(SetActiveUnit(ActiveUnit));
						sessionStorage.setItem("currentActiveUnit", JSON.stringify(ActiveUnit));
					}


					Setapiloading(false);
					return categorydata;
				})
				.catch((error) => {
					dispatch(fetchCategoryFailure(error.message));
					Setapiloading(false);
				});

			//Setcategories(categorydata);

			//dispatch(fetchCategorySuccess(categorydata));
			//let ActiveUnit = new Map();
			//ActiveUnit["activeCategory"] = categorydata?.[0];
			//ActiveUnit["activeSkill"] = categorydata?.[0]?.skill_List[0];
			//dispatch(SetActiveUnit(ActiveUnit));
			//	console.log(ActiveUnit);

			//Setapiloading(false);
		}
		getCategorySkillData();
	}, [updateUI]);

	useEffect(() => {
		const sessionCategoryCheck = sessionStorage.getItem('currentCategory')
		if (!sessionCategoryCheck) {
			sessionStorage.setItem('currentCategory', "Overall")
		}
	}, [])

	useEffect(() => {
		if (location?.pathname == "/Leaderboard") {
			setShowEventHeading(true)
			return
		} else {
			setShowEventHeading(false)
		}
	}, [location?.pathname])

	useEffect(() => {
		if (sessionStorage.getItem("currentActiveUnit")) {
			Setactivecategory(
				JSON.parse(sessionStorage.getItem("currentActiveUnit")).activeCategory
			);
		} else {
			Setactivecategory(categories?.[0]);
		}
	}, [categories]);

	if (apiloading) {
		return (
			<div>
				<Spinner animation="grow" variant="success" />
				<Spinner animation="grow" variant="danger" />
				<Spinner animation="grow" variant="warning" />
				<Spinner animation="grow" variant="info" />
			</div>
		);
	}

	// const activeLetterDefaults = {
	// 	IsletterBActive: false,
	// 	IsletterIActive: false,
	// 	IsletterNActive: false,
	// 	IsletterGActive: false,
	// 	IsletterOActive: false,
	// };

	const activeLetterDefaults = {};
	for (const [index, key] of letterArray.entries()) {
		activeLetterDefaults[`Isletter${key}${index}Active`] = false;
	}

	function CategoryClick(e, Category, Skill) {
		// console.log("Category is ", Category);
		e.preventDefault();
		let Dummy_skill = activeSkill;
		if (Skill != {}) {
			Dummy_skill = Skill;
		}
		let ActiveUnit = new Map();
		ActiveUnit["activeCategory"] = Category;
		ActiveUnit["activeSkill"] = Category.skill_List[0];
		sessionStorage.setItem("currentActiveUnit", JSON.stringify(ActiveUnit));
		sessionStorage.setItem("currentCategory", ActiveUnit?.activeCategory?.category_name);
		dispatch(SetActiveUnit(ActiveUnit));

		Setactivecategory(Category);
		sessionStorage.setItem(
			"activeLetter",
			JSON.stringify({
				...activeLetterDefaults,
				// IsletterBActive: true,
				[Object.keys(activeLetterDefaults)[0]]: true,
			})
		);

		// Reset Bingo letter B //
		if (location.pathname.match(eventMenu[1].nav_item?.toTitleCase())) {
			setTimeout(() => {
				window.location.reload();
			}, 200);
		}
		if (location.pathname == "/Badge") {
			window.location.reload();
		}

		if (location.pathname == "/Skills") {
			window.location.reload();
		}

		if (location.pathname == "/Leaderboard") {
			sessionStorage.setItem('leaderboardSort', "Overall")
			window.location.reload();
		}
		if (location.pathname == "/VirtualSessions") {
			// window.location.reload();
			sessionStorage.setItem('virtual', true)
		}
	}
	function SkillClick(e, Category, Skill) {
		// e.preventDefault();
		let ActiveUnit = new Map();
		ActiveUnit["activeCategory"] = Category;
		ActiveUnit["activeSkill"] = Skill;
		sessionStorage.setItem("currentActiveUnit", JSON.stringify(ActiveUnit));
		sessionStorage.setItem(
			"activeLetter",
			JSON.stringify({
				...activeLetterDefaults,
				[Object.keys(activeLetterDefaults)[0]]: true,
				// IsletterBActive: true,
			})
		);

		// Reset Bingo letter B //
		if (location.pathname.match(eventMenu[1].nav_item?.toTitleCase())) {
			window.location.reload();
		}
		Setactivecategory(Category);
		SetactiveSkill(Skill);
		dispatch(SetActiveUnit(ActiveUnit));

		Setsidebarview(!sidebarview);
		history.replace(eventMenu[1].nav_item?.toTitleCase());
		// history.replace("/Bingo/");
	}

	// ToDo : make it generic for all routes
	if (
		//(location.pathname == '/'+eventMenu[1].nav_item?.toTitleCase())
		location.pathname.toString() == "/" ||
		location.pathname == "/" + eventMenu[0].nav_item?.toTitleCase() ||
		location.pathname == "/" + eventMenu[1].nav_item?.toTitleCase()
		// location.pathname.match(/Leaderboard/) ||
		// location.pathname.match(/Badge/) ||
		// location.pathname.match(/VirtualSessions/) ||
		// location.pathname.match(/Faqs/)
	) {
		// arrowClass += " hide-element";
		arrowClass = arrowClass.replace(" hide-element", "");
	}
	return (
		<>
			<div className="leftsidebar animated fadeInLeft" id="Sidebar">
				<div
					className="logo-desk"
					onClick={() => {
						history.replace(eventMenu[0]?.nav_item?.toTitleCase());
						// history.replace("/");
					}}
				>
					<img src={event.event_image} className="img-fluid" />
					{/* <img src="img/homelogo.svg" className="img-fluid" /> */}
				</div>
				<div
					className="logo-mb"
					onClick={() => {
						history.replace(eventMenu[0].nav_item?.toTitleCase());
						// history.replace("/");
					}}
				>
					<img src={event.event_image} className="img-fluid" />
					{/* <img src="img/homelogo-mb.svg" className="img-fluid" /> */}
				</div>
				<div className="leftsidemenu">
					{!showEventHeading && <ul id="leftsidemenuList">
						{location?.pathname == "/Badge" && <li className={
							currentCategory == "Overall"
								? "active"
								: ""
						} onClick={() => {
							sessionStorage.setItem('currentCategory', "Overall")
							window.location.reload()
						}}>
							Overall
						</li>}
						{/* categories map for badges screen */}
						{location?.pathname == "/Badge" && categories?.map((element) => (
							<li
								key={element?.category_id}
								className={
									currentCategory == element?.category_name
										? "active"
										: ""
								}
								onClick={(e) => CategoryClick(e, element, {})}
							>
								{element.category_name}
							</li>
						))}
						{/* categories map for Other screens */}
						{location?.pathname != "/Badge" && categories?.map((element) => (
							<li
								key={element?.category_id}
								className={
									activecategory?.category_name == element?.category_name
										? "active"
										: ""
								}
								onClick={(e) => {
									CategoryClick(e, element, {})

								}}
							>
								{element.category_name}
							</li>
						))}
					</ul>}
					{showEventHeading && <ul id="leftsidemenuList">
						{/*{location?.pathname == "/Leaderboard" && <li className="active" style={{paddingLeft:'60px',paddingRight:'30px',cursor:'default'}}>
								{`${eventName} Leaderboard`}
							</li>}*/}
						{location?.pathname == "/Leaderboard" && categories?.map((element) => (
							<li
								key={element?.category_id}
								className={
									activecategory?.category_name == element?.category_name
										? "active"
										: ""
								}
								onClick={(e) => CategoryClick(e, element, {})}
							>
								{element.category_name}
							</li>
						))}
					</ul>}
				</div>
			</div>
			<span className={arrowClass} onClick={() => Setsidebarview(!sidebarview)}>
				<button
					type="button"
					className={sidebarview ? "slide-toggle rotatearrow" : "slide-toggle"}
				>
					<img src="img/slidearrow.png" />
				</button>
			</span>
			<div className="box" style={sidebarview ? sidebarOpen : sidebarClose}>
				<div className="box-inner">
					{categories?.map((element) => (
						<div key={element?.category_id} className="box-link">
							<div className="linksdiv">{element?.category_name}</div>
							<div className="textcont">{element?.category_desc}</div>
							<ul>
								{element?.skill_List.map((Skill) => (
									<li
										key={Skill?.skill_id}
										onClick={(e) => SkillClick(e, element, Skill)}
										className="skill_name mb-2"
									>
										<a>{Skill?.skill_name}</a>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			</div>
			<div style={{ clear: "both" }} />
			<div className="mobilearrowmenu">
				<div className="box-link-mob" style={{ display: "none" }}>
					{categories?.map((element) => (
						<div key={element?.category_id} className="box-link">
							<div className="linksdiv">{element?.category_name}</div>
							<div className="textcont">{element?.category_desc}</div>
							<ul>
								{element?.skill_List.map((Skill) => (
									<li key={Skill?.skill_id}>
										<a>{Skill?.skill_name}</a>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
				{!showEventHeading && <div className="mob-arrow-cont">
					<button className="mob-arrow">
						<i
							className="fas fa-chevron-down"
							onClick={() => Setsidebarview(!sidebarview)}
						/>
					</button>
				</div>}
			</div>
		</>
	);
}
{
	/* <a onClick={(e)=>SkillClick(e,element,Skill)}>
				  {Skill.skill_name}
				</a> */
}
export default React.memo(SideBar);
