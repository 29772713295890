import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";

import { Provider } from "react-redux";
import store from "./redux/store";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>,
	rootElement
);
unregister();
