import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import "./otp-verify.css";
import {
	setUserLogin,
	fetchUserSuccess,
	setUserLogout,
} from "../redux/UserDetails/UserActions";
import axios from "axios";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import OtpInput from "react-otp-input";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import BackgroundLoader from "../BackgroundLoader";
// import { throttle } from "lodash";
import _ from "lodash";
import Loader from "../Common/Loader";

function ValidateOtp() {
	const [otp, setotp] = useState("");
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const [ErrorMessage, setErrorMessage] = useState("Something went wrong!");
	const [ToastType, setToastType] = useState("danger");

	const [timerKey, setTimerKey] = useState(1);
	const [resetCount, setresetCount] = useState(3);

	const [SubmitOTPBtnStatus, setSubmitOTPBtnStatus] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	//
	let count = 0;
	const [resend, setResend] = useState(false);
	const [otpnum, setOtpNum] = useState("");
	const [minutes, setMin] = useState(4);
	const [seconds, setSec] = useState(59);
	const [timerCounter, setTimerCounter] = useState(600);
	const [sizeval, setSize] = useState(100);
	const userName = sessionStorage.getItem("userName");
	// const request = sessionStorage.getItem('request')
	// console.log(request, "storage")
	//

	if (location.state?.from_path != "/Login") {
		history.push("/Login");
	}
	// useEffect(() => {
	// 	// console.log("Path is ", location.pathname);
	// 	// console.log(location.key);
	// 	// console.log(location.state);
	// }, [location.state]);
	async function checklogin(e) {
		e.preventDefault();
		setSubmitOTPBtnStatus(true);
		// const request = otp;
		const request = {
			otp: otp,
		};
		setShowLoader(true);
		setTimeout(() => setSubmitOTPBtnStatus(false), 5000);

		//const response = await
		axios
			.post("/OTP/api/ValidateOTP", request)
			.then((resp) => {
				// console.log("verify otp ", resp);
				//debugger;
				if (resp?.data?.statusCode == 400 && resp?.data?.success == false) {
					setErrorMessage("Invalid OTP");
					setShow(true);
					setTimeout(() => history.replace("/Login"), 2000);
					setShowLoader(false);
				} else if (
					resp?.data?.statusCode != 200 &&
					resp?.data?.statusCode != 400 &&
					resp?.data?.success != true
				) {
					setErrorMessage("Invalid OTP");
					setShow(true);
					setTimeout(() => history.replace("/Login"), 2000);
					setShowLoader(false);
				}
				//console.log("OTP verify ", resp.data.statusCode);
				else if (resp?.data?.statusCode == 200 && resp?.data?.success == true) {
					//debugger;
					setShowLoader(false);

					dispatch(
						fetchUserSuccess(
							JSON.parse(base64_decode(location.state?.userSuccessData))
						)
					);
					dispatch(setUserLogin());

					// console.log("OTP UserInfo ", location?.state?.userLoginInfo);
					history.replace("/Events");
					// history.replace({
					// 	pathname: "/Events",
					// 	state: {
					// 		userLoginInfo: location?.state?.userLoginInfo,
					// 	},
					// });
				} else {
					setShowLoader(false);
					setShow(true);
				    setTimeout(() => history.replace("/Login"), 4000);
				}
			})
			.catch((error) => {
				//console.log(error);
				setShowLoader(false);

				//alert("Something went wrong");
				setShow(true);
				setTimeout(() => history.replace("/Login"), 2000);
			});
		// console.log(response);
		// if (response.data == "") {
		//   setShow(true);
		//   history.replace("/Login");
		// } else {
		//   dispatch(fetchUserSuccess(JSON.parse(base64_decode(response.data.data))));
		//   dispatch(setUserLogin());
		//   history.replace("/");
		// }
	}

	const resetTimer = () => {
		try {
			if (resetCount > 0) {
				setResend(false);
				setMin(0);
				setSec(59);
				// resendOtp();
				setTimerCounter(600);
				setSize(100);
				setresetCount(resetCount - 1);
				setOtpNum();
				// document?.getElementById("counter")?.click();
			} else if (resetCount === 0) {
				setResend(false);
				setOtpNum();
				setresetCount(0);

				//alert("Maximum OTP attempt reached. Please try later");
				setErrorMessage("Maximum OTP attempt reached. Please try later");
				setShow(true);
				history.push("./Login");
			}
		} catch (err) {
			//console.log(err.stack);
		}
	};
	const resendOtp = () => {
		const request = {
			userName: userName,
		};
		axios
			.post("OTP/api/ResendOTP")
			.then((res) => {
				//if (res.data.statusCode == 200 && res.data.Success == true) {
				if (res?.data?.statusCode == 200 && res?.data?.success == true) {
					resetTimer();
					// alert("OTP Resend Successful");
					setToastType("success");
					setErrorMessage("OTP was resent successfully");
					setShow(true);
					setTimerKey((pre) => pre + 1)
				} else {
					setErrorMessage("OTP resend failed");
					setShow(true);
				}
			})
			.catch((err) => {
				//console.log(err);
			});
	};

	const renderTime = (elapsedTime) => {
		// let remTime = (5 * 60 * 1000 - elapsedTime) / 1000;
		let remTime = 5 * 60 - elapsedTime;
		let mins = Math.floor(remTime / 60);
		let secs = Math.floor(remTime % 60);
		if (mins == 0 && secs == 1 && resetCount >= 0 && secs !== 0) {
			setResend(true);
		}
		return (
			<div className="text">
				{mins < 10 ? `0${mins}` : mins}:{secs < 10 ? `0${secs}` : secs}
			</div>
		);
	};

	return (
		<>
			<div>
				<meta charSet="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<title>Learning Events Portal</title>
				<meta name="description" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>
				<link rel="manifest" href="site.webmanifest" />
				<link rel="apple-touch-icon" href="icon.png" />
				<link rel="icon" href="favicon.ico" />
				<link rel="stylesheet" href="css/main.css" />
			</div>

			<div className="loginbg">
				<div className="container-fluid">
					<div className="row mainimg-mobile">
						<div className="col-md-12">
							<img
								src="img/LoginGraphic.png"
								className="img-fluid d-md-none"
								alt="image"
							/>
						</div>
					</div>
					<div className="row">
						<ToastContainer position="top-center">
							<Toast
								onClose={() => setShow(false)}
								show={show}
								delay={5000}
								autohide
								bg={ToastType}
							>
								<Toast.Body>
									<div style={{ color: "white", textAlign: "center" }}>
										{/*Invalid OTP*/}
										{ErrorMessage}
										{/*<br /> Please Try Again*/}
									</div>
								</Toast.Body>
							</Toast>
						</ToastContainer>
						<div className="col-lg-7 animated-area">
							<div className="wrapper">
								<div className="frame"> </div>
							</div>
							<div className="mainimg">
								<img src="img/LoginGraphic.png" />
								{/* <div className="circleimg">
									<img src="img/circle.png" id="loading" />
								</div> */}
							</div>
						</div>
						<div className="col-lg-5 login-area">
							<div
								className="login-cont animated fadeInRight "
								style={{ animationDelay: "0.5s" }}
							>
								{/* <h1 className="center_align_elmnt">
									Become a{" "}
									<img src="img/loginlogo.svg" style={{ marginTop: "-2px" }} />{" "}
									Champion
								</h1>
								<div className="para center_align_elmnt para_desc">
									A one stop solution to enhance data and digital literacy and
									build an expertise in future-focused technologies
								</div> */}
								<div className="login-logo">
									<img src="img/logo.svg" />
								</div>
								<div className="para">
									"A one stop solution to enhance your digital learning
									experience"
									{/* <section>Login with your Domain ID and Password</section> */}
									{/* A one stop solution to enhance data and digital literacy and
									build an expertise in future-focused technologies */}
								</div>
								<form id="login" autoComplete="off">
									<div className="form-row center_align_elmnt">
										<div className="col-12">
											<div className="text-center">
												<label>OTP has been sent on your Phone and Email</label>
												<p>
													Enter 6 digit{" "}
													<strong>
														<span className="text-primary">OTP</span>
													</strong>
												</p>
											</div>
											<div className=" mb-2 center_align_elmnt ">
												<div>
													<OtpInput
														value={otp}
														onChange={(e) => setotp(e)}
														autoComplete="on"
														shouldAutoFocus={true}
														pattern="^[1-9]\d*$"
														numInputs={6}
														isInputNum={true}
														containerStyle={"otp_input"}
														inputStyle={"otp_fields"}
														//separator={<span></span>}
														autocomplete="one-time-code"
													/>
												</div>
											</div>
											<small className="center_align_elmnt resend_otp_container">
												{/* Didn't recieve an OTP ? */}
												{resend ? (
													<a
														href="#!"
														onClick={(e) => {
															e.preventDefault();
															resendOtp();
															// resetTimer(e);
														}}
														style={{ marginLeft: "5px", cursor: "pointer" }}
													>
														Resend OTP
													</a>
												) : (
													<a
														className="anchorTag"
														style={{ marginLeft: "5px", cursor: "default" }}
													>
														Resend OTP
													</a>
												)}
											</small>
										</div>
										<div className="middle">
											<CountdownCircleTimer
												key={timerKey}
												size={120}
												strokeWidth={10}
												isPlaying
												duration={5 * 60}
												colors={[
													["#6610f2"],
													["#fc0810", 0.33],
													["#8e051d", 0.6],
												]}
												rotation="counterclockwise"
												style={{ transform: "none" }}
											>
												{({ elapsedTime }) => renderTime(elapsedTime)}
											</CountdownCircleTimer>
										</div>
										<div className="col-12 otp-sbmt-btn center_align_elmnt ">
											<button
												className="btn button1 hvr-shutter-out-horizontal otp-sbmt-btn"
												type="submit"
												disabled={SubmitOTPBtnStatus}
												onClick={_.throttle((e) => checklogin(e), 5000)}
											>
												Submit
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Loader
				show={showLoader}
				text={["Verifying OTP", <br />, "Please wait..."]}
			/>
		</>
	);
}

export default ValidateOtp;
