import {
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	FETCH_USER_FAILURE,
	SET_USER_LOGIN,
	SET_USER_LOGOUT,
	SET_USER_PROFILE_PHOTO,
} from "./UserActionTypes";

// export const fetchVideos = () => {
// 	return (dispatch) => {
// 		dispatch(fetchVideosRequest())
// 		axios
// 			.get("http://10.160.137.82:8080/cmp_cmpwebapi/api/content/0")
// 			.then((response) => {
// 				// response.data is the users
// 				const Videos = response.data;
// 				dispatch(fetchVideosSuccess(Videos));
// 			})
// 			.catch((error) => {
// 				// error.message is the error message
// 				dispatch(fetchVideosFailure(error.message));
// 			});
// 	};
// };

export const fetchUserRequest = () => {
	return {
		type: FETCH_USER_REQUEST,
	};
};

export const fetchUserSuccess = (User) => {
	return {
		type: FETCH_USER_SUCCESS,
		payload: User,
	};
};

export const fetchUserFailure = (error) => {
	return {
		type: FETCH_USER_FAILURE,
		payload: error,
	};
};

export const setUserLogin = () => {
	return {
		type: SET_USER_LOGIN,
	};
};
export const setUserLogout = () => {
	return {
		type: SET_USER_LOGOUT,
	};
};

