import { combineReducers } from "redux";
import UserActionReducer from "./UserDetails/UserActionReducer";
import CategoryReducer from "./CategoryDetails/CategoryActionReducer";
import ContentReducer from "./ContentDetails/ContentActionReducer";
import ActiveUnitReducer from "./ActiveUnitDetails/ActiveUnitActionReducer";
import SkillReducer from "./SkillDetails/SkillReducer";
import RefreshBingoReducer from "./RefreshBingo/RefreshBingoReducer";
import EventActionReducer from "./EventDetails/EventActionReducer";

const rootReducer = combineReducers({
	user: UserActionReducer,
	event:EventActionReducer,
	category: CategoryReducer,
	content: ContentReducer,
	skill: SkillReducer,
	activeUnit: ActiveUnitReducer,
	refreshBingo:RefreshBingoReducer
});

export default rootReducer;
