import React from "react";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";

export default function Loader({ show, text = "Please wait..." }) {
    return (
        <>
            <Modal show={show} centered backdropClassName="backdrop-modal-loader" backdrop="static">
                <Modal.Body style={{ height: '140px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', background: "white", borderRadius: "10px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Modal.Title>{text}</Modal.Title>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="danger" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
