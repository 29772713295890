import {
	FETCH_USER_SUCCESS,
	SET_USER_LOGIN,
	SET_USER_LOGOUT,
	SET_USER_PROFILE_PHOTO,
} from "./UserActionTypes";
import { initialState } from "../initialState";
// import { appInitialState } from "./../appInitialState";
import rootReducer from "./../rootReducer";

const UserActionReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_SUCCESS:
			return {
				...state,
				user: action.payload,
			};
		case SET_USER_LOGIN:
			return {
				...state,
				isLoggedIn: true,
			};
		case SET_USER_LOGOUT:
			state = [];
			localStorage.clear();
			return rootReducer(undefined, "SET_USER_LOGOUT");
		default:
			return state;
	}
};

export default UserActionReducer;
