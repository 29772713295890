import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
//import profileicon from "../img/SampleProfile.jpg";
//import profileicon from "../img/profile-icon.png";
import profileicon from "../img/user.svg";
import Hls from "hls.js";
import "../css/NavMenu.css";
import {
	fetchUserSuccess,
	setUserLogout,
} from "../redux/UserDetails/UserActions";
import "./Common.css";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import profileedit from "../img/profile-edit.svg";
import RefreshInitialLoad from "../APICaller/RefreshInitialLoad";
import clearAllCookies from "./clearAllCookie";
import clearAllCache from "./clearAllCache";

function Header() {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	// const [feedBackUrl, setfeedBackUrl] = useState(
	// 	process.env.REACT_APP_Feedback_URL
	// );
	// const [introVideoUrl, setintroVideoUrl] = useState(
	// 	process.env.REACT_APP_About_URL
	// );

	const feedBackUrl = useSelector(
		(state) => state.event.event.event_feedback_url
	);
	const [VideoActive, SetVideoActive] = useState(false);
	const [userMenu, SetuserMenu] = useState(false);
	const [NavbarExpand, SetNavbarExpand] = useState(false);
	const [UpdateProfileModal, SetUpdateProfileModal] = useState(false);
	const [UpdateProfileStatus, SetUpdateProfileStatus] = useState({
		text: "Unable to Upload Profile Pic",
		type: "danger",
	});
	const [Showalert, SetShowalert] = useState(false);
	const [Imagefile, Setimagefile] = useState();
	const [profile_icon, Setprofile_icon] = useState(null);
	const eventMenu = useSelector((state) => state.event.navigationMenuItems);

	const [imgTypeValidation, setImgTypeValidation] = useState(false);
	const [imgErrMsg, setImgErrMsg] = useState("")

	// console.log("Event-menu",eventMenu);
	const onButtonClick = (e, para) => {
		e.preventDefault();
		history.replace(para);
	};
	const event = useSelector((state) => state.event.event.event_id);
	// const onButtonClick = (e, page, para) => {
	// 	e.preventDefault();
	// 	history.replace(para);
	// };
	const [activeState, SetactiveState] = useState(location.pathname);
	// console.log("beforr setting path ", location.pathname);
	if (location.pathname != activeState) {
		SetactiveState(location.pathname);
	}
	// console.log("after setting path ", activeState);
	const [showDropdown, setShowDropdown] = useState(false);

	function dropdown() {
		setShowDropdown(!showDropdown);
	}
	/* Title case function */
	String.prototype.toTitleCase = function () {
		return this.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};
	const UserLoginInfo = useSelector((state) => state.user.user);
	const introVideoUrl = useSelector(
		(state) => state.event.event.event_intro_link
	);
	// useEffect(() => {
	// axios.get("/api/LinkProvider/FeedbackFormLink").then((res) => {
	// 	if (res) {
	// 		setfeedBackUrl(res.data.data);
	// 	}
	// });
	// axios.get("/api/LinkProvider/IntroVideoLink").then((res) => {
	// 	if (res) {
	// 		setintroVideoUrl(res.data.data);
	// 	}
	// });
	// }, []);

	useEffect(() => {
		if (VideoActive) {
			const video = document.getElementById("video");
			const hls = new Hls();
			//const url =
			//	"https://media-sit.jio.com/media/SocialEngVideo20220125225406/SocialEngVideo20220125225406masterplaylist.m3u8";
			const url = introVideoUrl;
			hls.loadSource(url);
			hls.attachMedia(video);
			hls.on(Hls.Events.MANIFEST_PARSED, function () {
				video.play();
			});
		}
	}, [VideoActive]);

	function LogoutUser() {
		axios.post("Home/api/Logout");
		dispatch(setUserLogout());
		localStorage.clear(); //for localStorage
		sessionStorage.clear(); //for sessionStorage
		RefreshInitialLoad();
		clearAllCookies()
		clearAllCache()
		history.replace("/Login");
	}

	function PhotoUpdate(file) {
		Setimagefile(file);
		setImgTypeValidation(false)
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = async function () {
			Setprofile_icon(await reader.result);
		};
		if (!file.name.match(/\.(jpeg|png)$/)) {
			setImgTypeValidation(true)
			// console.log(imgTypeValidation, "validation")
			return false;
		}
	}

	function UpdateProfile() {
		let Updated_photo = profile_icon.substring(profile_icon.indexOf(";") + 8);
		let request = { file: Imagefile };
		const formData = new FormData();
		formData.append("file", Imagefile);
		// console.log(request);

		if (imgTypeValidation) {
			setImgErrMsg("Please upload only jpeg and png files.")
			// console.log("image invalid format")
		}
		else axios
			.post("api/Login/ProfilePhoto", formData)
			.then((response) => {
				//console.log(response.data.data);
				if (response.data.data != null) {
					let url = response.data.data;
					let temp = UserLoginInfo;
					temp["empProfilePic"] = url;
					dispatch(fetchUserSuccess(temp));
					SetUpdateProfileModal(false);
					var Modaldata = {
						text: "Updated Profile Photo Successfully",
						type: "success",
					};
					SetUpdateProfileStatus(Modaldata);
					SetShowalert(true);
				} else {
					SetUpdateProfileModal(false);
					var Modaldata = {
						text: "Unable to Upload Profile Pic",
						type: "danger",
					};
					SetUpdateProfileStatus(Modaldata);
					SetShowalert(true);
				}
			})
			.catch((error) => {
				SetUpdateProfileModal(false);
				var Modaldata = {
					text: "Unable to Upload Profile Pic",
					type: "danger",
				};
				SetUpdateProfileStatus(Modaldata);
				SetShowalert(true);
				//console.log(error);
			});
	}

	const image_url = process.env.REACT_APP_IMAGE_URL;
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<div
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{/* Render custom icon here */}
			<img
				className="profile_icon user_img"
				src={
					UserLoginInfo.empProfilePic == null
						? profileicon
						: image_url + UserLoginInfo.empProfilePic
				}
			/>
		</div>
	));
	// console.log(profileicon, "profileicon")
	// console.log(profile_icon, "profile_icon")
	// console.log(UserLoginInfo.empProfilePic, "UserLoginInfo.empProfilePic")

	// console.log('event menu is ',eventMenu);
	const navMenuItems =
		eventMenu?.length === 0
			? null
			: eventMenu?.map((item, index) => (
				//   console.log("itrx", item.nav_item);
				<React.Fragment key={index}>
					<li
						className={
							activeState.match(
								"/" + item?.nav_item.toTitleCase().replace(/\s/g, "")
							)
								? "nav-item active"
								: "nav-item"
							// activeState == '/'+item?.nav_item.toTitleCase(). ? "nav-item active" : "nav-item"
						}
					>
						<a
							className="nav-link"
							href="#"
							onClick={(e) => {
								sessionStorage.removeItem('leaderboardSort')
								e.preventDefault();
								// console.log("nabar =>", item?.nav_item);
								SetNavbarExpand(false);
								if (
									item?.nav_item.includes("BINGO") ||
									item?.nav_item.includes("DRONACHARYA") ||
									item?.nav_item.includes("GROW")
								) {
									setTimeout(() => {
										window.location.reload();
									}, 200);
								}
								// debugger;
								if (item?.nav_item?.toLowerCase().includes("new to")) {
									SetNavbarExpand(false);
									SetVideoActive(true);
								} else {
									onButtonClick(
										e,
										item?.nav_item.toTitleCase().replace(/\s/g, "")
									);
								}
							}}
						>
							{item?.nav_item}
						</a>
					</li>
				</React.Fragment>
			));

	return (
		<>
			<div>
				<meta charSet="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<title> Learning Events Portal</title>
				<meta name="description" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>
				<link rel="manifest" href="site.webmanifest" />
				<link rel="apple-touch-icon" href="icon.png" />
				<link rel="icon" href="favicon.ico" />
				<link rel="stylesheet" href="css/main.css" />
			</div>
			<ToastContainer position="top-center" style={{ zIndex: "3000" }}>
				<Toast
					onClose={() => SetShowalert(false)}
					show={Showalert}
					delay={1500}
					autohide
					bg={UpdateProfileStatus.type}
				>
					<Toast.Body>
						<div style={{ color: "white", textAlign: "center" }}>
							{UpdateProfileStatus.text}
						</div>
					</Toast.Body>
				</Toast>
			</ToastContainer>
			<nav className="navbar navbar-expand-xl navbar-dark animated fadeInDown">
				<button
					className="navbar-toggler collapsed"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span
						className="navbar-toggler-icon"
						onClick={() => {
							SetNavbarExpand(!NavbarExpand);
						}}
					/>
				</button>
				<div
					className={
						NavbarExpand
							? "navbar-collapse collapse show"
							: "navbar-collapse collapse"
					}
					id="navbarSupportedContent"
					style={{}}
				>
					<ul className="navbar-nav mr-auto" style={{ marginLeft: "4px" }}>
						{navMenuItems}
						{/* <li
							className={activeState === "/" ? "nav-item active" : "nav-item"}
						>
							<a
								className="nav-link"
								href="#"
								onClick={(e) => {
									SetNavbarExpand(false);
									onButtonClick(e, "/", "/");
								}}
							>
								SKILLS{" "}
							</a>
						</li>
						<li
							className={
								activeState === "/Bingo" ? "nav-item active" : "nav-item"
							}
						>
							<a
								className="nav-link"
								href="#"
								onClick={(e) => {
									SetNavbarExpand(false);
									onButtonClick(e, "/Bingo", "/Bingo");
								}}
							>
								BINGO
							</a>
						</li>
						<li
							className={
								activeState === "/Badge" ? "nav-item active" : "nav-item"
							}
						>
							<a
								className="nav-link"
								href="#"
								onClick={(e) => {
									SetNavbarExpand(false);
									onButtonClick(e, "/Badge", "/Badge");
								}}
							>
								BADGE
							</a>
						</li>
						<li
							className={
								activeState === "/LeaderBoard" ? "nav-item active" : "nav-item"
							}
						>
							<a
								className="nav-link"
								href="#"
								onClick={(e) => {
									SetNavbarExpand(false);
									onButtonClick(e, "/LeaderBoard", "/LeaderBoard");
								}}
							>
								LEADERBOARD
							</a>
						</li>
						<li
							className={
								activeState === "/VirtualSessions"
									? "nav-item active"
									: "nav-item"
							}
						>
							<a
								className="nav-link"
								href="#"
								onClick={(e) => {
									SetNavbarExpand(false);
									onButtonClick(e, "/VirtualSessions", "/VirtualSessions");
								}}
							>
								VIRTUAL SESSIONS
							</a>
						</li>
						<li
							className={
								activeState === "/faq" ? "nav-item active" : "nav-item"
							}
						>
							<a
								className="nav-link"
								href="#"
								onClick={(e) => {
									SetNavbarExpand(false);
									onButtonClick(e, "/Faq", "/faq");
								}}
							>
								FAQs
							</a>
						</li>
						<li className="nav-item">
							<a
								className="nav-link"
								onClick={(e) => {
									SetNavbarExpand(false);
									SetVideoActive(true);
								}}
							>
								NEW TO 3D CHAMPION?
							</a>
						</li> */}
					</ul>
					{/* <div>
						<a href="#" className="notification">
							<span>
								<i className="far fa-bell" />
							</span>
							<span className="badge"></span>
						</a>
					</div> */}
					{/* <form className="form-inline my-2 my-lg-0 searchcont">
						<input
							className="form-control mr-sm-2"
							type="text"
							placeholder="Search"
						/>
					</form> */}
					<div className="user ">
						<Dropdown bsPrefix="btn-group">
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							></Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									className="dropdown-item user_name"
									type="button"
								>
									{!UserLoginInfo ? "" : UserLoginInfo["fullName"]}
								</Dropdown.Item>
								<Dropdown.Item
									className="dropdown-item"
									type="button"
									hidden={
										feedBackUrl == null || feedBackUrl === "" ? true : false
									}
									onClick={() => {
										window.open(feedBackUrl, "_blank");
									}}
								>
									Feedback
								</Dropdown.Item>
								<Dropdown.Item
									className="dropdown-item"
									type="button"
									onClick={() => history.push("/Events")}
								>
									Events
								</Dropdown.Item>
								<Dropdown.Item
									className="dropdown-item no-border"
									type="button"
									onClick={() => LogoutUser()}
								>
									Sign out
								</Dropdown.Item>
							</Dropdown.Menu>
							<div className="profileedit">
								<img
									src={profileedit}
									onClick={() => {
										//SetUpdateProfileModal(false);
										SetUpdateProfileModal(true);
									}}
								/>
							</div>
						</Dropdown>
					</div>
				</div>
			</nav>
			<Modal show={UpdateProfileModal}>
				<div
					className="modal fade show"
					id="exampleModal2"
					tabIndex={-1}
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-modal="true"
					style={{ display: "block", paddingLeft: "17px" }}
				>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span
										aria-hidden="true"
										onClick={() => {
											SetUpdateProfileModal(false);
										}}
									>
										×
									</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="task-cont-popup2">
									<div className="field">
										{profile_icon == null ? (
											<></>
										) : (
											<span className="pip">
												<img className="profile_icon" src={profile_icon} />
												<br />
											</span>
										)}
										<input
											type="file"
											accept="image/png, image/jpeg"
											onChange={(e) => {
												e.preventDefault();
												PhotoUpdate(e.target.files[0]);
											}}
										/>
									</div>
									<small>Please note - Max file size allowed is 5MB</small>
									<br />
									<br />
									{/* {event == 4 ? (
										<small>
											<p>Earn 20 points on uploading your profile pic.</p>
										</small>
									) : (
										<small>
											{UserLoginInfo?.empProfilePic ? <p> </p> : <p>Earn 100 points on uploading your profile pic. </p>}
										</small>
									)} */}
									{imgTypeValidation && <p className="text-danger fs-6">{imgErrMsg}</p>}
									<button
										className="btn button3 hvr-shutter-out-horizontal mt-3"
										type="submit"
										onClick={() => {
											UpdateProfile();
										}}
									>
										Submit
									</button>
									<br />
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal show={VideoActive}>
				<div
					id="myModal"
					className="modal fade show"
					style={{ display: "block", paddingRight: "17px" }}
					aria-modal="true"
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" style={{ color: "whitesmoke" }}>
									{/* New to 3D Champion? */}
									{eventMenu?.length > 0 && eventMenu[eventMenu?.length - 1]?.nav_item}
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									onClick={() => {
										SetVideoActive(false);
									}}
								>
									×
								</button>
							</div>
							<div className="modal-body">
								<div className="embed-responsive embed-responsive-16by9">
									{/* <iframe id="cartoonVideo" className="embed-responsive-item"  width={560} height={315} src="https://www.youtube.com/embed/5GcQtLDGXy8" allowFullScreen /> */}
									<video
										id="video"
										controls
										width={560}
										height={315}
										muted={false}
										className="embed-responsive-item"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default React.memo(Header);
