import React from "react";
import { useHistory } from "react-router-dom";

import "./ErrorFallback.css";

function ErrorFallback() {
	const history = useHistory();

	return (
		<>
			<h1 className="page_heading">Oops! Something went wrong.</h1>
			<p className="page_para">
				<br />
				Try &nbsp;<kbd>Ctrl</kbd> + <kbd>F5</kbd>
				<br />
				<br />
				OR
				<br />
				<br />
				<kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd>
				<br />
				{/* Sorry, but the page you were trying to view does not exist. */}
			</p>
			<button
				className="joinnowbtn back-btn"
				onClick={(e) => {
					e.preventDefault();
					history.replace("/Events");
					window.history.go("/Events");
				}}
			>
				{" "}
				Go Back{" "}
			</button>
		</>
	);
}

export default ErrorFallback;
