import { FETCH_ACTIVEUNIT, SET_ACTIVEUNIT ,UPDATE_ACTIVE_SKILL} from "./ActiveUnitActionTypes";

export const fetchActiveUnitRequest = () => {
	return {
		type: FETCH_ACTIVEUNIT,
	};
};

export const SetActiveUnit = (Unit) => {
	return {
		type: SET_ACTIVEUNIT,
		payload: Unit,
	};
};

export const updateActiveSkill = (Skill) => {
	return {
		type: UPDATE_ACTIVE_SKILL,
		payload: Skill,
	};
};
