import axios from "axios";
import { setUserLogout } from "../redux/UserDetails/UserActions";

export default function checkSession(response, dispatch, history) {
			if (response?.data?.statusCode == 444) {
				// alert("Session Expired,Please Login again !!!");
				axios.post("Home/api/Logout");
				dispatch(setUserLogout());
				localStorage.clear(); //for localStorage
				sessionStorage.clear();
				history.replace("/Login");
			}
}


