export const initialState = {
	isLoggedIn: false,
	loading: false,
	user: {},
	error: "",
	content: [],
	category: [],
	skill: [],
	userpath: [],
	activeUnit: {},
	resourceURL: "",
	refreshBingo: false,
	event: '',
	quizLetters: [],
	navigationMenuItems: [],
};
