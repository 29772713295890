import React, {
	useLayoutEffect,
	useEffect,
	useState,
	useCallback,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Alert from "react-bootstrap/Alert";
import "./Login.css";
import RefreshInitialLoad from "../APICaller/RefreshInitialLoad";
import Loader from "../Common/Loader";
import clearAllCookies from "../Common/clearAllCookie";
import clearAllCache from "../Common/clearAllCache";
// import Spinner from "react-bootstrap/Spinner";

export default function Login() {
	const history = useHistory();

	const [domainID, SetDomainID] = useState("");
	const [password, Setpassword] = useState("");

	const [check, setCheck] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [captcha, setCaptcha] = useState("");
	const [captchaKey, setCaptchaKey] = useState(0);
	const [Captchashow, setCaptchaShow] = useState(false);
	const [showCaptchError, setshowCaptchError] = useState(false);
	const [captchErrorMsg, setcaptchErrorMsg] = useState("");
	const [ip, setIP] = useState("");
	const [showLoader, setShowLoader] = useState(false);
	let capRef = React.createRef();
	let cbRef = React.createRef();

	const [show, setShow] = useState(false);
	const [LoginBtnStatus, setLoginBtnStatus] = useState(false);

	const isAuthenticated = useSelector((state) => state.user);

	async function checklogin(e) {
		e.preventDefault();

		// if (isAuthenticated["isLoggedIn"] || isAuthenticated.user.isLoggedIn) {
		// 	console.log("User is logged in and redirec to homepage");
		// 	history.push("/");
		// 	return;
		// } else {
		setShowLoader(true);
		const request = {
			authtoken:
				base64_encode(domainID) +
				":" +
				base64_encode(password) +
				":" +
				base64_encode(captcha),
			event_id: 1,
			user_id: " ",
			sessionID: " ",
			loginTime: null,
			client_IP: " ",
			serverIP: " ",
			deviceType: " ",
		};
		request.deviceType = getDeviceType();
		request.client_IP = ip;
		setLoginBtnStatus(true);
		// sessionStorage.setItem('request', request)
		//debugger;
		const response = await axios
			.post("api/Login/User", request)
			.catch((error) => {
				//console.log(error);
				setShow(true);
			    setLoginBtnStatus(false);
			    setShowLoader(false);
			    history.replace("/Login");
			});
		//	console.log("Response Login is ", response);
		//debugger;
		if (response.data == "" || response.data.statusCode == 400) {
			setShow(true);
			setLoginBtnStatus(false);
			setShowLoader(false);
			history.replace("/Login");
		} else if (response.data.statusCode == 406) {
			setShowLoader(false);
			setCaptchaShow(true);
			setshowCaptchError(true);
			setcaptchErrorMsg(response.data.userMsg);
			setLoginBtnStatus(false);
		} else if (response.data.statusCode == 200) {
			// if (response.data.statusCode == 200 && response.data.success == true) {
			// dispatch(fetchUserSuccess(JSON.parse(base64_decode(response.data.data))));
			// dispatch(setUserLogin());
			// console.log(
			// 	"EMP Response is ",
			// 	JSON.parse(base64_decode(response.data.data))
			// );
			setShowLoader(false);
			setTimeout(() => {
				history.replace({
					pathname: "/ValidateOtp",
					state: {
						userSuccessData: response.data.data,
						from_path: "/Login",
					},
				});
			}, 100);
		} else {
			setShow(true);
			setLoginBtnStatus(false);
			setShowLoader(false);
			history.replace("/Login");
		}
	}

	const CustomAErrorAlert = (alertMsg) => (
		<Alert style={{ textAlign: "center" }} variant={"danger"}>
			{alertMsg}
		</Alert>
	);
	const getDeviceType = () => {
		const ua = navigator.userAgent;
		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
			return "tablet";
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				ua
			)
		) {
			return "mobile";
		}
		return "desktop";
	};
	const ipFinderURL = process.env.REACT_APP_IPFinder_URL;
	// eslint-disable-next-line
	const getIPData = useCallback(async () => {
		const res = await axios.get(ipFinderURL);
		//console.log(res.data);
		setIP(res.data.IPv4);
	}, [ip]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (showToast) {
			setTimeout(() => {
				setShowToast(false);
			}, 5000);
		}
	}, [showToast]);
	useEffect(() => {
		getIPData();
		window.setTimeout(() => {
			setCaptchaKey(Math.floor(Math.random() * 100 + 1));
		}, 700);
	}, [getIPData.apply, ip]);

	useEffect(() => {
		if (!isAuthenticated["isLoggedIn"]) {
			RefreshInitialLoad();
			clearAllCookies()
			clearAllCache()
		}
	}, []);

	// if (showLoader) {
	// 	return (
	// 		<div>
	// 			<Spinner animation="grow" variant="success" />
	// 			<Spinner animation="grow" variant="danger" />
	// 			<Spinner animation="grow" variant="warning" />
	// 			<Spinner animation="grow" variant="info" />
	// 		</div>
	// 	);
	// }
	return (
		<>
			<div>
				<meta charSet="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<title>Learning Events Portal</title>
				<meta name="description" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>
				<link rel="manifest" href="site.webmanifest" />
				<link rel="apple-touch-icon" href="icon.png" />
				<link rel="icon" href="favicon.ico" />
				<link rel="stylesheet" href="css/main.css" />
			</div>

			<div className="loginbg">
				<div className="container-fluid">
					<div className="row mainimg-mobile">
						<div className="col-md-12">
							<img
								src="img/LoginGraphic.png"
								className="img-fluid"
								alt="image"
							/>
						</div>
					</div>
					<div className="row">
						<ToastContainer position="top-center">
							<Toast
								onClose={() => setShow(false)}
								show={show}
								delay={1500}
								autohide
								bg="danger"
							>
								<Toast.Body>
									<div style={{ color: "white", textAlign: "center" }}>
										Invalid Credentials
										<br /> Please Try Again
									</div>
								</Toast.Body>
							</Toast>
						</ToastContainer>
						<ToastContainer position="top-center">
							<Toast
								onClose={() => setCaptchaShow(false)}
								show={Captchashow}
								delay={1500}
								autohide
								bg="danger"
							>
								<Toast.Body>
									<div style={{ color: "white", textAlign: "center" }}>
										Invalid Captcha
										<br /> Please Try Again
									</div>
								</Toast.Body>
							</Toast>
						</ToastContainer>
						<div className="col-lg-7 animated-area">
							<div className="wrapper">
								<div className="frame"></div>
							</div>
							<div className="mainimg">
								<img src="img/LoginGraphic.png" />
								{/* <div className="circleimg">
									<img src="img/circle.png" id="loading" />
								</div> */}
							</div>
						</div>
						<div className="col-lg-5 login-area">
							<div
								className="login-cont animated fadeInRight "
								style={{ animationDelay: "0.5s" }}
							>
								{/* {CustomAErrorAlert("HI")} */}
								{/* <h1>
									Become a{" "}
									<img src="img/loginlogo.svg" style={{ marginTop: "-2px" }} />{" "}
									Champion
								</h1> */}
								<div className="login-logo">
									<img src="img/logo.svg" />
								</div>
								<div className="para">
									"A one stop solution to enhance your digital learning
									experience"
									{/* A one stop solution to enhance data and digital literacy and
									build an expertise in future-focused technologies */}
									{/* <section>Login with your Domain ID and Password</section> */}
								</div>
								<section
									className="ms-1 text-secondary mb-1"
									style={{ fontSize: "12px" }}
								>
									Login with your Domain ID and Password
								</section>
								<div>
									<form method="post" id="login" autoComplete="off">
										<div className="form-row">
											<div className="col-12">
												<div className="input-group mb-2">
													<div className="input-group-prepend">
														<span
															className="input-group-text"
															id="basic-addon1"
														>
															<i className="fas fa-user" />
														</span>
													</div>
													<input
														name="username"
														type="text"
														className="input form-control"
														id="username"
														placeholder="Enter User ID"
														aria-label="Enter User ID"
														aria-describedby="basic-addon1"
														autoComplete="off"
														onChange={(e) => {
															e.preventDefault();
															SetDomainID(e.target.value);
															// sessionStorage.setItem('userName', e.target.value)
														}}
													// onFocus="this.removeAttribute('readonly');"
													/>
												</div>
											</div>
											<div className="col-12">
												<div className="input-group">
													<div className="input-group-prepend">
														<span
															className="input-group-text"
															id="basic-addon1"
														>
															<i className="fas fa-lock" />
														</span>
													</div>
													<input
														name="password"
														type="password"
														className="input form-control"
														id="password"
														placeholder="Password"
														required={true}
														aria-label="Password"
														aria-describedby="basic-addon1"
														autoComplete="off"
														onChange={(e) => {
															e.preventDefault();
															Setpassword(e.target.value);
														}}
													// onFocus="this.removeAttribute('readonly');"
													/>
													<div className="input-group-append">
														{/* onClick="password_show_hide();" */}
														<span className="input-group-text">
															{/* <i className="fas fa-eye" id="show_eye" /> */}
															{/* <i className="fas fa-eye-slash d-none" id="hide_eye" /> */}
														</span>
													</div>
												</div>
											</div>
											<div className="col-12 captcha_input">
												{showCaptchError && CustomAErrorAlert(captchErrorMsg)}
												{/* Forgot password? */}
												{/* <br />
                        <Captcha /> */}
												<>
													<div>
														<div className="input-group">
															<div className="input-group-prepend">
																<span
																	className="input-group-text"
																	style={{ background: "#e3f0f5" }}
																>
																	<img
																		src={`/api/Login/captcha?${captchaKey}`}
																	/>
																</span>
															</div>
															<input
																type="text"
																className="form-control captcha-input"
																placeholder="Enter Captcha"
																value={captcha}
																ref={capRef}
																// onKeyDown={(e) => {
																//   //  if (e.keyCode === 13) {
																//   if (e.key === "Enter") {
																//     cbRef.current.focus();
																//   }
																// }}
																onChange={(e) => {
																	e.preventDefault();
																	setCaptcha(e.target.value);
																}}
																maxLength={6}
															/>
															<div className="input-group-append">
																<a
																	onClick={() => {
																		setCaptchaKey(captchaKey + 1);
																		setCaptcha("");
																		capRef.current.focus();
																	}}
																	className="btn btn-primary sync_icon"
																	style={{
																		display: "flex",
																		justifyContent: "centre",
																		alignItems: "center",
																		borderRadius: "12px",
																	}}
																// className="btn btn-primary sync-btn sync_icon"
																>
																	<i className="fas fa-sync center sync-icon-btn text-light"></i>
																</a>
															</div>
														</div>
														{/* <small>You entered the wrong captcha.</small> */}
													</div>
												</>
											</div>
											{/* <div className="col-12" style={{ paddingLeft: "4rem" }}> */}
											<div className="col-12 login-btn ">
												<button
													className="login-btn btn button1 hvr-shutter-out-horizontal login-btn"
													type="submit"
													disabled={LoginBtnStatus}
													onClick={(e) => checklogin(e)}
												>
													Login to Continue
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Loader show={showLoader} />
		</>
	);
}
