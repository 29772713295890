import React from "react";
import Header from "./Header";
import "./Common.css";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "./SideBar";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
	fetchUserSuccess,
	setUserLogout,
} from "../redux/UserDetails/UserActions";

const Layout = ({ children }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const logout = () => {
		// alert("Session Expired due to user being idle,Please Login again !!!");
		axios.post("Home/api/Logout");
		dispatch(setUserLogout());
		localStorage.clear();
		sessionStorage.clear();
		//console.log("Logged out");
		history.replace("/Login");
	};

	const event = useSelector((state) => state.event.event);
	const location = useLocation();
	if (location.pathname.match(/Faqs/) || location.pathname.match(/Events/)) {
		return (
			<div id="Home" className={event.event_id == 3 ? "retailbg" : "homebg"}>
				<div className="header-outer">
					<div className="outer">
						<div className="leftsidebar-bingotask">
							<div className="logo-desk">
								<img src={event.event_image} className="img-fluid" />
								{/* <img src="img/homelogo.svg" className="img-fluid" /> */}
							</div>
							<div className="logo-mb">
								<img src={event.event_image} className="img-fluid" />
								{/* <img src="img/homelogo-mb.svg" className="img-fluid" /> */}
							</div>
						</div>
						<div
							className="rightsidebar-bingotask"
							style={{ paddingRight: "5rem" }}
						>
							<Header style={{ paddingTop: "0rem" }} />
						</div>
					</div>
					{children}
				</div>
			</div>
		);
	}

	return (
		<div id="Home" className={event.event_id == 3 ? "retailbg" : "homebg"}>
			<SideBar />
			<div className="rightside">
				<div className="right-container">
					<Header />
					{children}
				</div>
			</div>
		</div>
	);
};

export default React.memo(Layout);
