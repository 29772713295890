import {
	SET_EVENT_DETAILS,
	SET_QUIZ_LETTERS,
	SET_NAV_ITEMS,
} from "./EventActionTypes";
import { initialState } from "../initialState";

const EventActionReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_EVENT_DETAILS:
			return {
				...state,
				event: action.payload,
			};
		case SET_QUIZ_LETTERS:
			return {
				...state,
				quizLetters: action.payload,
			};
		case SET_NAV_ITEMS:
			return {
				...state,
				navigationMenuItems: action.payload,
			};
		default:
			return state;
	}
};

export default EventActionReducer;
