import { SET_EVENT_DETAILS, SET_QUIZ_LETTERS, SET_NAV_ITEMS } from "./EventActionTypes";


export const setEventDetails = (eventDetails) => {
	return {
		type: SET_EVENT_DETAILS,
		payload:eventDetails
	};
};
export const setQuizLetters = (quizLetters) => {
	return {
		type: SET_QUIZ_LETTERS,
		payload: quizLetters,
	};
};
export const setNavItems = (navItems) => {
	return {
		type: SET_NAV_ITEMS,
		payload: navItems,
	};
};

