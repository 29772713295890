import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist"; // imports from redux-persist
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import logger from "redux-logger";
import thunk from "redux-thunk";

import rootReducer from "./rootReducer";
const persistConfig = {
	// configuration object for redux-persist
	key: "le_x_i__",
	storage: storage, // define which storage to use
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
	persistedReducer,
	composeWithDevTools(applyMiddleware(thunk))
);

export default store;

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export { store, persistor };
