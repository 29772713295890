import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import IdleTimer from "react-idle-timer";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
	setUserLogout,
} from "../redux/UserDetails/UserActions";
import { useHistory } from "react-router-dom";

function IdleSessionContainer(props) {
	const idelTimeRef = useRef(null);
	const timeoutDuaration = 10800 * 1000;
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
    const dispatch = useDispatch();
	const history= useHistory();

	const onIdle = () => {
		setShow(true);
		setTimeout(() => {
					axios.post("Home/api/Logout");
					dispatch(setUserLogout());
					localStorage.clear();
					sessionStorage.clear();
					//console.log("Logged out");
					history.replace("/Login");
		}, 5000);
	};

	return (
		<>
			<IdleTimer ref={idelTimeRef} timeout={timeoutDuaration} onIdle={onIdle}>
				{props.children}
			</IdleTimer>

			<Modal
				show={show}
				onHide={handleClose}
				style={{ color: "whitesmoke", backgroundColor: "transparent" }}
			>
				<Modal.Header closeButton closeVariant="white">
					<Modal.Title >Warning!</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="task-cont-popup2">
						<img
							src={
								"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyhA6QlAXwBmXP0YCZEXzjbNNjuqvKkiUzS6eNEHJTNFRI0CfMZpBUmTBOpzr25wgetoU&usqp=CAU"
							}
							height="50px"
							width="50px"
						/>
						<br/>
						<br/>
						<strong>You will be logged out soon due to inactivity! </strong>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default IdleSessionContainer;
