import axios from "axios";
function RefreshInitialLoad() {
	axios
		.get("/api/Login/refresh_session")
		.then((res) => {})
		.catch((err) => {
			console.log(err);
		});
}
export default RefreshInitialLoad;
