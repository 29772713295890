import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.user);
  return (
    <>{isAuthenticated["isLoggedIn"] ?  children  : <Redirect to="/Login" />}</>
  );
};

export default PrivateRoute;
