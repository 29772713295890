import {
	FETCH_SKILL_REQUEST,
	FETCH_SKILL_SUCCESS,
	FETCH_SKILL_FAILURE,
	SET_SKILL,
	UPDATE_SKILL,
	//REFRESH_BINGO,
} from "./SkillActionTypes";
import { initialState } from "../initialState";
import rootReducer from "../rootReducer";

const SkillReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_SKILL_SUCCESS:
			return {
				...state,
				skill: action.payload,
			};
		case SET_SKILL:
			return {
				...state,
				skill: action.payload,
			};
		case UPDATE_SKILL:
			return {
				...state,
				skill: action.payload,
			};
		// case REFRESH_BINGO:
		// 	return {
		// 		...state,
		// 		refreshBingo: action.payload,
		// 	};
		default:
			return state;
	}
};

export default SkillReducer;
