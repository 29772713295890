import {
	FETCH_CATEGORY_REQUEST,
	FETCH_CATEGORY_SUCCESS,
	FETCH_CATEGORY_FAILURE,
} from "./CategoryActionTypes";

export const fetchCategoryRequest = () => {
	return {
		type: FETCH_CATEGORY_REQUEST,
	};
};

export const fetchCategorySuccess = (Category) => {
	return {
		type: FETCH_CATEGORY_SUCCESS,
		payload: Category,
	};
};

export const fetchCategoryFailure = (error) => {
	return {
		type: FETCH_CATEGORY_FAILURE,
		payload: error,
	};
};
